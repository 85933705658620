import React from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import { withTranslation } from 'react-i18next';
import { CsvBuilder } from 'filefy';
import jsPDF from 'jspdf';
import moment from 'moment';
import { Paper, Typography } from '@material-ui/core';

class MaterialTableExtend extends React.Component {

    constructor(props) {
        super();
        this.state = {
            page: props.currentPage ? props.currentPage : 0,
            pageSize: this.calculatePageSize(props.data.length)
        };
    }

    calculatePageSize(dataLength) {
        if (dataLength <= 5) return 5;
        if (dataLength <= 10) return 10;
        if (dataLength <= 20) return 20;
        if (dataLength <= 30) return 30;
        if (dataLength <= 40) return 40;
        if (dataLength <= 50) return 50;
        if (dataLength <= 60) return 60;
        if (dataLength <= 70) return 70;
        if (dataLength <= 80) return 80;
        if (dataLength <= 90) return 90;
        return 100;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.props.data !== nextProps.data;
    }

    render() {
        const { t } = this.props;

        let translations = t('material_table.tableStrings', { returnObjects: true });
        if (this.props.deleteText && this.props.deleteTooltip) {
            translations.body['deleteTooltip'] = this.props.deleteTooltip;
            translations.body['editRow']['deleteText'] = this.props.deleteText;
        }

        const Title = (title) => {
            return <Typography style={{ fontWeight: 500, margin: '20px 0' }} variant='h5' component='h2'>{title}</Typography>;
        };

        const columns = this.props.columns.map(column => {
            switch (column.field) {
                case 'startDate':
                    return { ...column, title: 'Start Dt', width: 80 };
                case 'endDate':
                    return { ...column, title: 'End Dt', width: 80 };
                case 'description':
                    return { ...column, title: 'Desc', width: 120 };
                case 'status':
                    return { ...column, title: 'Stat', width: 60 };
                case 'createdAt':
                    return { ...column, title: 'Crtd At', width: 80 };
                case 'updatedAt':
                    return { ...column, title: 'Upd At', width: 80 };
                default:
                    return { ...column, width: 100 };
            }
        });

        return (
            <div style={{ overflowX: 'auto', maxHeight: 'calc(100vh - 200px)' }}>
                <MaterialTable
                    {...this.props}
                    columns={columns}
                    title={Title(this.props.title)}
                    tableRef={ref => {
                        this.table = ref;
                    }}
                    style={{ maxWidth: '100%', width: '100%' }}
                    components={{
                        Toolbar: props => (
                            <React.Fragment>
                                <MTableToolbar {...props} />
                                {this.props.buttons}
                                {this.props.toolBar}
                            </React.Fragment>
                        ),
                        Container: props => <Paper {...props} elevation={0} />
                    }}
                    localization={translations}
                    options={{
                        filtering: true,
                        exportButton: true,
                        doubleHorizontalScroll: true,
                        fixedColumns: this.props.fixedColumns ? this.props.fixedColumns : null,
                        actionsCellStyle: this.props.alignButtonsCenter ? {
                            margin: "0 auto",
                            flexGrow: "1",
                            justifyContent: "center",
                            border: "none"
                        } : null,
                        exportCsv: (columns, renderData) => {
                            if (this.props.exportCsv) {
                                this.props.exportCsv(columns, renderData, this.table);
                            } else {
                                columns = columns.filter(columnDef => columnDef.field !== 'actions');
                                new CsvBuilder(this.props.title + '.csv')
                                    .setColumns(columns.map(col => col.title))
                                    .addRows(renderData.map(rowData => columns.map(col => rowData[col.field])))
                                    .exportFile();
                            }
                        },
                        exportPdf: (columns, renderData) => {
                            if (this.props.exportPdf) {
                                this.props.exportPdf(columns, renderData, this.table);
                            } else {
                                const doc = new jsPDF({ orientation: 'l' });

                                columns = columns.filter(columnDef => !columnDef.hidden && columnDef.field && columnDef.export !== false);

                                const columnTitles = columns.map(columnDef => columnDef.title);

                                const pdfData = renderData.map(rowData =>
                                    columns.map(columnDef => {
                                        if (moment(rowData[columnDef.field], moment.ISO_8601, true).isValid()) {
                                            return moment(rowData[columnDef.field]).format('DD/MM/YYYY HH:mm');
                                        }

                                        if (columnDef.field.includes(".")) {
                                            return rowData[columnDef.field.split(".")[0]][columnDef.field.split(".")[1]];
                                        }

                                        return columnDef.lookup ? columnDef.lookup[rowData[columnDef.field]] : rowData[columnDef.field];
                                    }),
                                );
                                const title = this.table.props.title.props.children;
                                doc.text(title, 15, 8, { baseline: 'top' });
                                doc.autoTable({
                                    head: [columnTitles],
                                    body: pdfData,
                                    didDrawPage: function (data) {
                                        doc.setFontSize(20);
                                        doc.setTextColor(40);
                                    }
                                });

                                doc.save(`${title}.pdf`);
                            }
                        },
                        initialPage: this.state.page,
                        selection: this.props.selection ? true : false,
                        actionsColumnIndex: this.props.actionsColumnIndex,
                        pageSizeOptions: [5, 10, 20, 100],
                        pageSize: this.state.pageSize,
                        rowStyle: rowData => {
                            if (rowData.tableData.checked) {
                                return { backgroundColor: 'rgb(228, 229, 229)', whiteSpace: 'pre-line' };
                            }
                            return { whiteSpace: 'pre-line' };
                        },
                        headerStyle: {
                            whiteSpace: 'pre-line'
                        }
                    }}
                    onChangePage={(page) => {
                        if (this.props.setCurrentPage) {
                            this.props.setCurrentPage(page);
                        }
                    }}
                />
            </div>
        );
    }
}

export default withTranslation('common')(MaterialTableExtend);