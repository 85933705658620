import { servicesConstants } from '../../constants/services_constants'
import { history } from '../../helpers/history'
import { serviceService } from '../../services/servicesService'
import { alertActions } from '../actions/alert_actions'
import { userActions } from '../actions/user_actions'
import i18next from 'i18next'
import { userService } from '../../services/userService'
import { ERROR_CODES, SERVICES_TYPE } from '../../constants/types'
import { vehiclesActions } from './vehicles_actions'
import moment from 'moment'

const getValue = value => (typeof value === 'string' ? value.toUpperCase() : value.toString())

const DETAIL_ERROR_CODES = {
    DuplicatedCustomerTipology: 'DuplicatedCustomerTipology',
    InvalidParameters: 'InvalidParameters',
    RemovedService: 'RemovedService',
    ServiceTypeNotValid: 'ServiceTypeNotValid',
    ServiceLineInvalidStops: 'ServiceLineInvalidStops',
    ServiceNotFound: 'ServiceNotFound',
    VehicleOutsideWorkingHours: 'VehicleOutsideWorkingHours',
    ExpeditionOutOfServiceWorkingHours: 'ExpeditionOutOfServiceWorkingHours',
    SameNameServiceExists: 'SameNameServiceExists',
    InvalidAnticipationForAssignmentWheelService: 'InvalidAnticipationForAssignmentWheelService',
    PickupAndDropOffAutoCompleteNotAvailable: 'PickupAndDropOffAutoCompleteNotAvailable',
    ServiceHasTrips: 'ServiceHasTrips',
    CalendarPeriodsCollapsing: 'CalendarPeriodsCollapsing',
    ShiftHoursCollapisng: 'ShiftHoursCollapisng',
    ShiftHourLimitExceed: 'ShiftHourLimitExceed',
    RequestShiftHoursCollapisng: 'RequestShiftHoursCollapisng',
    VehicleHasTrips: 'VehicleHasTrips',
    WorkingBankHolidaysHoursCollapsing: 'WorkingBankHolidaysHoursCollapsing',
    BreakShiftHourLimitExceed: 'BreakShiftHourLimitExceed',
    BreakShiftHoursCollapisng: 'BreakShiftHoursCollapisng'
}

export const serviceActions = {
    getAll,
    getServiceList,
    getAllWithZonesAndVehicles,
    getById,
    insert,
    update,
    clearResults,
    getTypesService,
    remove,
    getTypesPrices,
    getRoutes,
    getRoutesFromVehicle,
    getSpecificRoute,
    getAvailableVehicleCapacity,
    setRouteId,
    getRouteId,
    getZonesWithStops,
    updateServiceStops,
    getWeekDays,
    removeExcludeDays,
    removeExcludedWorkingDays,
    setExcludeDays,
    setExcludedWorkingDays,
    setWorkDays,
    setRangeCalendar,
    setWorkingRangeCalendar,
    setCalendar,
    setServiceDataEdit,
    addTimeRequestShiftDays,
    addTimeShiftDays,
    addTimeShiftBreak,
    addTimeShiftHours,
    removeTimeRequestShiftDays,
    removeTimeShiftDays,
    removeTimeShiftBreaks,
    removeTimeShiftHours,
    setShiftDays,
    setShiftBreaks,
    setRequestShiftDays,
    setShiftHours,
    getJourneys,
    getRouteStops,
    getJourneyTrips,
    getServicesTypes,
    getTripStatuses,
    addActivityJourney,
    getJourneyActivities,
    getOldJourneys,
    getDriverActivities,
    addActivityDriver,
    getCustomerActivities,
    addActivityCustomer,
    setServiceFilter,
    setRouteFilter,
    setJourneyFilter,
    setAbsences,
    removeCurrentPenalty,
    setCustomerBilling,
    setHolderBilling,
    getPeriodicity,
    getTypeTipologies,
    getTripActivities,
    addActivityTrip,
    getAnticipationTypes,
    setJourneyFilterDate,
    getWheelJourneys,
    getServicesByOtp,
    getServiceLine,
    getServiceLineStopsAvailable,
    setServiceLine,
    cleanServiceLine,
    setExpeditions,
    getServiceBilling,
    getKPITypes,
    getKPIsByType,
    getKPIFiltersByGroup,
    getKPITimeUnits,
    getKPITripOrigins,
    getCalendar,
    enable,
    disable,
    sendPushToServiceUsers,
    saveTableFilters,
    saveJourneysTableFilters,
    cleanJourneyFilters
}

function setServiceDataEdit(body) {
    return {
        type: servicesConstants.SET_SERVICE_DATA_EDIT,
        body,
    }
}

function cleanServiceLine() {
    return { type: servicesConstants.CLEAN_SERVICE_LINE }
}

function setAbsences(absences, customerId) {
    return dispatch => {
        dispatch(request())
        serviceService.setAbsences(absences, customerId).then(
            result => {
                dispatch(success(result))
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken())
                    dispatch(setAbsences(absences, customerId))
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.SET_ABSENCES_REQUEST }
    }

    function success(absences) {
        return { type: servicesConstants.SET_ABSENCES_SUCCESS, absences }
    }

    function failure(error) {
        return { type: servicesConstants.SET_ABSENCES_FAILURE, error }
    }
}

function removeCurrentPenalty(absencesId) {
    return dispatch => {
        dispatch(request())
        serviceService.removeCurrentPenalty(absencesId).then(
            service => {
                dispatch(success(absencesId))
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken())
                    dispatch(removeCurrentPenalty(absencesId))
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.REMOVE_CURRENT_PENALTY_REQUEST }
    }

    function success(absencesId) {
        return {
            type: servicesConstants.REMOVE_CURRENT_PENALTY_SUCCESS,
            absencesId,
        }
    }

    function failure(error) {
        return { type: servicesConstants.REMOVE_CURRENT_PENALTY_FAILURE, error }
    }
}

function setCustomerBilling(typology) {
    return dispatch => {
        dispatch(request())
        serviceService.setCustomerBilling(typology).then(
            result => {
                dispatch(success(result))
                dispatch(alertActions.success(i18next.t('services.services.typology.success')))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(setCustomerBilling(typology))
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.services.forbiddenUpdate')))
                        break
                    default:
                        try {
                            let parsedError = JSON.parse(error)
                            let detailError = parsedError.detail
                            switch (detailError) {
                                case DETAIL_ERROR_CODES.DuplicatedCustomerTipology:
                                    dispatch(alertActions.error(i18next.t('services.services.typology.DuplicatedCustomerTipology')))
                                    break
                                default:
                                    dispatch(alertActions.error(i18next.t('services.services.typology.error')))
                            }
                        } catch (e) {
                            dispatch(alertActions.error(error.toString()))
                        }
                }
                dispatch(failure(error, typology))
            },
        )
    }

    function request() {
        return { type: servicesConstants.SET_TYPOLOGY_REQUEST }
    }

    function success(serviceUpdated) {
        return { type: servicesConstants.SET_TYPOLOGY_SUCCESS, serviceUpdated }
    }

    function failure(error, refreshTypology) {
        return {
            type: servicesConstants.SET_TYPOLOGY_FAILURE,
            error,
            refreshTypology,
        }
    }
}

function setHolderBilling(typology) {
    return dispatch => {
        dispatch(request())
        serviceService.setHolderBilling(typology).then(
            result => {
                dispatch(success(result))
                dispatch(alertActions.success(i18next.t('services.services.typology.success')))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(setHolderBilling(typology))
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.services.forbiddenUpdate')))
                        break
                    default:
                        try {
                            let parsedError = JSON.parse(error)
                            let detailError = parsedError.detail
                            switch (detailError) {
                                case DETAIL_ERROR_CODES.DuplicatedCustomerTipology:
                                    dispatch(alertActions.error(i18next.t('services.services.typology.DuplicatedCustomerTipology')))
                                    break
                                default:
                                    dispatch(alertActions.error(i18next.t('services.services.typology.error')))
                            }
                        } catch (e) {
                            dispatch(alertActions.error(error.toString()))
                        }
                }
                dispatch(failure(error, typology))
            },
        )
    }

    function request() {
        return { type: servicesConstants.SET_TYPOLOGY_REQUEST }
    }

    function success(serviceUpdated) {
        return { type: servicesConstants.SET_TYPOLOGY_SUCCESS, serviceUpdated }
    }

    function failure(error, refreshTypology) {
        return {
            type: servicesConstants.SET_TYPOLOGY_FAILURE,
            error,
            refreshTypology,
        }
    }
}

function setServiceLine(serviceLine) {
    return dispatch => {
        dispatch(request())
        serviceService.setServiceLine(serviceLine).then(
            result => {
                dispatch(success(result))
                dispatch(alertActions.success(i18next.t('services.services.serviceLine.success')))
                dispatch(getServiceLineStopsAvailable(serviceLine.serviceId, 10))
                dispatch(getServiceLineStopsAvailable(serviceLine.serviceId, 20))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(setServiceLine(serviceLine))
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.services.forbiddenUpdate')))
                        break
                    default:
                        try {
                            let parsedError = JSON.parse(error)
                            let detailError = parsedError.detail
                            switch (detailError) {
                                case DETAIL_ERROR_CODES.InvalidParameters:
                                    dispatch(alertActions.error(i18next.t('services.services.serviceLine.InvalidParameters')))
                                    break
                                case DETAIL_ERROR_CODES.RemovedService:
                                    dispatch(alertActions.error(i18next.t('services.services.serviceLine.RemovedService')))
                                    break
                                case DETAIL_ERROR_CODES.ServiceTypeNotValid:
                                    dispatch(alertActions.error(i18next.t('services.services.serviceLine.ServiceTypeNotValid')))
                                    break
                                case DETAIL_ERROR_CODES.ServiceLineInvalidStops:
                                    dispatch(alertActions.error(i18next.t('services.services.serviceLine.ServiceLineInvalidStops')))
                                    break
                                case DETAIL_ERROR_CODES.ServiceNotFound:
                                    dispatch(alertActions.error(i18next.t('services.services.serviceLine.ServiceNotFound')))
                                    break
                                default:
                                    dispatch(alertActions.error(i18next.t('services.services.serviceLine.error')))
                            }
                        } catch (e) {
                            dispatch(alertActions.error(error.toString()))
                        }
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.SET_SERVICE_LINE_REQUEST }
    }

    function success(line) {
        return { type: servicesConstants.SET_SERVICE_LINE_SUCCESS, line }
    }

    function failure(error) {
        return { type: servicesConstants.SET_SERVICE_LINE_FAILURE, error }
    }
}

function setExpeditions(expeditions, serviceId, direction, expeditionMinutes) {
    return dispatch => {
        dispatch(request())
        serviceService.setExpeditions(expeditions, serviceId, direction, expeditionMinutes).then(
            result => {
                dispatch(success(result))
                dispatch(alertActions.success(i18next.t('services.services.expeditions.success')))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(setExpeditions(expeditions, serviceId, direction))
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.services.forbiddenUpdate')))
                        break
                    default:
                        try {
                            let parsedError = JSON.parse(error)
                            let detailError = parsedError.detail
                            switch (detailError) {
                                case DETAIL_ERROR_CODES.InvalidParameters:
                                    dispatch(alertActions.error(i18next.t('services.services.serviceLine.InvalidParameters')))
                                    break
                                case DETAIL_ERROR_CODES.RemovedService:
                                    dispatch(alertActions.error(i18next.t('services.services.serviceLine.RemovedService')))
                                    break
                                case DETAIL_ERROR_CODES.ServiceTypeNotValid:
                                    dispatch(alertActions.error(i18next.t('services.services.serviceLine.ServiceTypeNotValid')))
                                    break
                                case DETAIL_ERROR_CODES.ServiceLineInvalidStops:
                                    dispatch(alertActions.error(i18next.t('services.services.serviceLine.ServiceLineInvalidStops')))
                                    break
                                case DETAIL_ERROR_CODES.ServiceNotFound:
                                    dispatch(alertActions.error(i18next.t('services.services.serviceLine.ServiceNotFound')))
                                    break
                                case DETAIL_ERROR_CODES.VehicleOutsideWorkingHours:
                                    dispatch(alertActions.error(i18next.t('services.services.serviceLine.VehicleOutsideWorkingHours')))
                                    break
                                case DETAIL_ERROR_CODES.ExpeditionOutOfServiceWorkingHours:
                                    dispatch(alertActions.error(i18next.t('services.services.serviceLine.ExpeditionOutOfServiceWorkingHours')))
                                    break
                                default:
                                    dispatch(alertActions.error(i18next.t('services.services.serviceLine.error')))
                            }
                        } catch (e) {
                            if (error != 403) dispatch(alertActions.error(error.toString()))
                        }
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.SET_EXPEDITIONS_REQUEST }
    }

    function success(expeditions) {
        return { type: servicesConstants.SET_EXPEDITIONS_SUCCESS, expeditions }
    }

    function failure(error) {
        return { type: servicesConstants.SET_EXPEDITIONS_FAILURE, error }
    }
}

function getAvailableVehicleCapacity(vehicleId) {
    return dispatch => {
        dispatch(request())
        serviceService.getAvailableVehicleCapacity(vehicleId).then(
            results => {
                dispatch(success(results))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(getAvailableVehicleCapacity())
                        break

                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.services.forbiddenGetAll')))
                        break
                    default:
                        dispatch(alertActions.error(i18next.t('services.services.getAllError')))
                }
                dispatch(failure(error))
            },
        )
    }
    function request() {
        return { type: servicesConstants.GET_AVAIABLE_VEHICLE_CAPACITY_REQUEST }
    }

    function success(results) {
        return {
            type: servicesConstants.GET_AVAIABLE_VEHICLE_CAPACITY_SUCCESS,
            results,
        }
    }

    function failure(error) {
        return {
            type: servicesConstants.GET_AVAIABLE_VEHICLE_CAPACITY_FAILURE,
            error,
        }
    }
}

function getServiceBilling(serviceId) {
    return dispatch => {
        dispatch(request())
        serviceService.getServiceBilling(serviceId).then(
            results => {
                dispatch(success(results))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(getServiceBilling(serviceId))
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.services.forbiddenGetAll')))
                        break
                    default:
                        dispatch(alertActions.error(i18next.t('services.services.getAllError')))
                }
                dispatch(failure(error))
            },
        )
    }
    function request() {
        return { type: servicesConstants.GET_BILLING_REQUEST }
    }

    function success(results) {
        return {
            type: servicesConstants.GET_BILLING_SUCCESS,
            results,
        }
    }

    function failure(error) {
        return {
            type: servicesConstants.GET_BILLING_FAILURE,
            error,
        }
    }
}

function filterPlainArray(array, filters) {
    const filterKeys = Object.keys(filters)
    return array.filter(item => {
        item = item.serviceResponse ? item.serviceResponse : item
        // validates all filter criteria
        return filterKeys.every(key => {
            // ignores an empty filter
            if (!filters[key].length) return true
            return filters[key].find(filter => getValue(filter) === getValue(item[key]))
        })
    })
}

function getAll(includeRemoved = false, filters = null) {
    return dispatch => {
        dispatch(request())
        serviceService.getAll(includeRemoved).then(
            results => {
                let all = results.slice() //copy only by value
                if (filters != null) {
                    results = filterPlainArray(results, filters)
                }
                dispatch(success(results, all))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(getAll())
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.services.forbiddenGetAll')))
                        break
                    default:
                        dispatch(alertActions.error(i18next.t('services.services.getAllError')))
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.GETALL_REQUEST }
    }

    function success(results, all) {
        return { type: servicesConstants.GETALL_SUCCESS, results, all }
    }

    function failure(error) {
        return { type: servicesConstants.GETALL_FAILURE, error }
    }
}

function getServiceList() {
    return dispatch => {
        dispatch(request())
        serviceService.getALLWithPagination().then(
            results => {
                dispatch(success(results))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(getServiceList())
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.services.forbiddenGetAll')))
                        break
                    default:
                        dispatch(alertActions.error(i18next.t('services.services.getAllError')))
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.GET_SERVICE_LIST_REQUEST }
    }

    function success(results) {
        return { type: servicesConstants.GET_SERVICE_LIST_SUCCESS, results }
    }

    function failure(error) {
        return { type: servicesConstants.GET_SERVICE_LIST_FAILURE, error }
    }

}

function getAllWithZonesAndVehicles(filters = null) {
    return dispatch => {
        dispatch(request())
        serviceService.getAllWithZonesAndVehicles().then(
            results => {
                let all = results.slice() //copy only by value
                if (filters != null) {
                    results = filterPlainArray(results, filters)
                }
                dispatch(success(results, all))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(getAllWithZonesAndVehicles())
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.services.forbiddenGetAll')))
                        break
                    default:
                        dispatch(alertActions.error(i18next.t('services.services.getAllError')))
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.GETALL_REQUEST }
    }

    function success(results, all) {
        return { type: servicesConstants.GETALL_SUCCESS, results, all }
    }

    function failure(error) {
        return { type: servicesConstants.GETALL_FAILURE, error }
    }
}

function getServicesByOtp(otpId) {
    return dispatch => {
        dispatch(request())
        serviceService.getServicesByOtp(otpId).then(
            results => {
                dispatch(success(results, results))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(getServicesByOtp())
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.services.forbiddenGetById')))
                        break
                    default:
                        dispatch(alertActions.error(i18next.t('services.services.getAllError')))
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.GETALL_REQUEST }
    }

    function success(results, all) {
        return { type: servicesConstants.GETALL_SUCCESS, results, all }
    }

    function failure(error) {
        return { type: servicesConstants.GETALL_FAILURE, error }
    }
}

function getById(id, redirect) {
    return dispatch => {
        dispatch(request(id))
        serviceService.getById(id).then(
            service => {
                dispatch(success(service))
                dispatch(getCalendar(service.serviceResponse.calendar, service.serviceResponse.serviceType))
                dispatch(vehiclesActions.getByOtpIdWithOutServices(service?.serviceResponse?.otpId))
                if (redirect) history.push('/services/' + id)
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(getById(id))
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.services.forbiddenGetById')))
                        break
                    default:
                        dispatch(alertActions.error(i18next.t('services.services.getAllError')))
                }
                dispatch(failure(error))
            },
        )
    }

    function request(id) {
        return { type: servicesConstants.DETAIL_REQUEST, id }
    }

    function success(service) {
        return { type: servicesConstants.DETAIL_SUCCESS, service }
    }

    function failure(error) {
        return { type: servicesConstants.DETAIL_FAILURE, error }
    }
}

function getZonesWithStops(id) {
    return dispatch => {
        dispatch(request(id))
        serviceService.getServiceZonesWithStops(id).then(
            zones => {
                serviceService.servicesTypes().then(types => {
                    const prof = makeEnum(types)
                    dispatch(success(zones, prof))
                })
            },
            error => {

                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(getZonesWithStops(id))
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.services.forbiddenGetById')))
                        break
                    default:
                        break
                }
                dispatch(failure(error))
            },
        )
    }

    function request(id) {
        return { type: servicesConstants.GET_ZONES_STOPS_REQUEST, id }
    }

    function success(zones, types) {
        return { type: servicesConstants.GET_ZONES_STOPS_SUCCESS, zones, types }
    }

    function failure(error) {
        return { type: servicesConstants.GET_ZONES_STOPS_FAILURE, error }
    }
}

function update(service) {
    return dispatch => {
        dispatch(request())
        serviceService.update(service).then(
            service => {
                dispatch(success(service))
                history.push('/services')
                dispatch(alertActions.success(i18next.t('services.services.updateSuccess')))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(update(service))
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.services.forbiddenUpdate')))
                        break
                    default:
                        try {
                            let parsedError = JSON.parse(error)
                            let detailError = parsedError.detail
                            switch (detailError) {
                                case DETAIL_ERROR_CODES.SameNameServiceExists:
                                    dispatch(alertActions.error(i18next.t('services.services.SameNameServiceExists')))
                                    break
                                case DETAIL_ERROR_CODES.InvalidAnticipationForAssignmentWheelService:
                                    dispatch(alertActions.error(i18next.t('services.services.InvalidAnticipationForAssignmentWheelService')))
                                    break
                                case DETAIL_ERROR_CODES.ServiceHasTrips:
                                    dispatch(alertActions.error(i18next.t('services.services.calendar.VehicleHasTrips')))
                                    break
                                case DETAIL_ERROR_CODES.PickupAndDropOffAutoCompleteNotAvailable:
                                    dispatch(alertActions.error(i18next.t('services.services.PickupAndDropOffHiddenNotAvailable')))
                                    break
                                default:
                                    dispatch(alertActions.error(i18next.t('services.services.updateError')))
                            }
                        } catch (e) {
                            if (error != 403) dispatch(alertActions.error(error.toString()))
                        }
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.EDIT_REQUEST }
    }

    function success(service) {
        return { type: servicesConstants.EDIT_SUCCESS, service }
    }

    function failure(error) {
        return { type: servicesConstants.EDIT_FAILURE, error }
    }
}

function updateServiceStops(id, stops) {
    return dispatch => {
        dispatch(request())
        serviceService.updateZoneStops(id, stops).then(
            result => {
                dispatch(success(result))
                history.push('/services')
                dispatch(alertActions.success(i18next.t('services.services.updateSuccess')))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(updateServiceStops(id, stops))
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.services.forbiddenUpdate')))
                        break
                    default:
                        dispatch(alertActions.error(i18next.t('services.services.updateError')))
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.UPDATE_ZONES_STOPS_REQUEST }
    }

    function success(result) {
        return { type: servicesConstants.UPDATE_ZONES_STOPS_SUCCESS, result }
    }

    function failure(error) {
        return { type: servicesConstants.UPDATE_ZONES_STOPS_FAILURE, error }
    }
}

function clearResults() {
    return dispatch => {
        dispatch(clear())
    }

    function clear() {
        return { type: servicesConstants.CLEAR_RESULTS }
    }
}

function getTypesService() {
    return dispatch => {
        dispatch(request())
        serviceService.servicesTypes().then(
            types => {
                const prof = makeEnum(types)
                dispatch(success(prof))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(getTypesService())
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.services.forbiddenGetAll')))
                        break
                    default:
                        break
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.GET_TYPES_REQUEST }
    }

    function success(types) {
        return { type: servicesConstants.GET_TYPES_SUCCESS, types }
    }

    function failure(error) {
        return { type: servicesConstants.GET_TYPES_FAILURE, error }
    }
}

function getServicesTypes() {
    return dispatch => {
        dispatch(request())
        serviceService.getServicesTypes().then(
            types => {
                const prof = makeEnum(types)
                dispatch(success(prof))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(getTypesService())
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.services.forbiddenGetAll')))
                        break
                    default:
                        break
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.GET_SERVICES_TYPES_REQUEST }
    }

    function success(types) {
        return { type: servicesConstants.GET_SERVICES_TYPES_SUCCESS, types }
    }

    function failure(error) {
        return { type: servicesConstants.GET_SERVICES_TYPES_FAILURE, error }
    }
}

function getWeekDays() {
    return dispatch => {
        dispatch(request())
        serviceService.getWeekDays().then(
            result => {
                const days = makeEnum(result)
                days.push(days.shift());
                dispatch(success(days))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(getWeekDays())
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.services.forbiddenGetAll')))
                        break
                    default:
                        break
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.GET_WEEK_DAYS_REQUEST }
    }

    function success(days) {
        return { type: servicesConstants.GET_WEEK_DAYS_SUCCESS, days }
    }

    function failure(error) {
        return { type: servicesConstants.GET_WEEK_DAYS_FAILURE, error }
    }
}

function getTypesPrices() {
    return dispatch => {
        dispatch(request())
        serviceService.servicesPrices().then(
            types => {
                const prof = makeEnum(types)
                dispatch(success(prof))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(getTypesPrices())
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.services.forbiddenGetAll')))
                        break
                    default:
                        break
                }
                dispatch(failure(error))

            },
        )
    }

    function request() {
        return { type: servicesConstants.GET_PRICES_REQUEST }
    }

    function success(prices) {
        return { type: servicesConstants.GET_PRICES_SUCCESS, prices }
    }

    function failure(error) {
        return { type: servicesConstants.GET_PRICES_FAILURE, error }
    }
}

function insert(service) {
    return dispatch => {
        dispatch(request())
        serviceService.insert(service).then(
            service => {
                dispatch(success(service))
                if (service.hasZoneWithStops && [SERVICES_TYPE.tadService, SERVICES_TYPE.assignmentWheelService].includes(service.serviceType)) {
                    history.push('/services/addStop/' + service.id)
                } else {
                    history.push('/services')
                }

                dispatch(alertActions.success(i18next.t('services.services.insertSuccess')))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(insert(service))
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.services.forbiddenInsert')))
                        break
                    default:
                        try {
                            let parsedError = JSON.parse(error)
                            let detailError = parsedError.detail
                            switch (detailError) {
                                case DETAIL_ERROR_CODES.SameNameServiceExists:
                                    dispatch(alertActions.error(i18next.t('services.services.SameNameServiceExists')))
                                    break
                                case DETAIL_ERROR_CODES.InvalidAnticipationForAssignmentWheelService:
                                    dispatch(alertActions.error(i18next.t('services.services.InvalidAnticipationForAssignmentWheelService')))
                                    break
                                case DETAIL_ERROR_CODES.PickupAndDropOffAutoCompleteNotAvailable:
                                    dispatch(alertActions.error(i18next.t('services.services.PickupAndDropOffHiddenNotAvailable')))
                                    break
                                default:
                                    dispatch(alertActions.error(i18next.t('services.services.insertError')))
                            }
                        } catch (e) {
                            dispatch(alertActions.error(error.toString()))
                        }
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.ADD_REQUEST }
    }

    function success(service) {
        return { type: servicesConstants.ADD_SUCCESS, service }
    }

    function failure(error) {
        return { type: servicesConstants.ADD_FAILURE, error }
    }
}

function getCalendar(calendar, serviceType) {
    return dispatch => {
        if (calendar.shiftBreaks.length === 0 && serviceType != SERVICES_TYPE.regularWithSchedule) {
            calendar.shiftBreaks.push({
                shiftDays: {
                    weekDays: [],
                    earliestStart: '',
                    latestArrival: '',
                    endsNextDay: true,
                },
                stop: {}
            })
        }
        dispatch(request(calendar))
    }
    function request(calendar) {
        return { type: servicesConstants.GET_CALENDAR_SUCCESS, calendar }
    }
}

function setCalendar(calendar, idService, hasRestictrionsOnrequests) {
    return dispatch => {
        dispatch(request())
        serviceService.setCalendar(calendar, idService, hasRestictrionsOnrequests).then(
            result => {
                dispatch(success(result))
                history.push('/services')
                dispatch(alertActions.success(i18next.t('services.services.calendar.success')))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(setCalendar(calendar, idService))
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.services.forbiddenUpdate')))
                        break
                    default:
                        try {
                            let parsedError = JSON.parse(error)
                            let detailError = parsedError.detail
                            switch (detailError) {
                                case DETAIL_ERROR_CODES.CalendarPeriodsCollapsing:
                                    dispatch(alertActions.error(i18next.t('services.services.calendar.collapseCalendar')))
                                    break
                                case DETAIL_ERROR_CODES.ShiftHoursCollapisng:
                                    dispatch(alertActions.error(i18next.t('services.services.calendar.collapseShiftDays')))
                                    break
                                case DETAIL_ERROR_CODES.ShiftHourLimitExceed:
                                    dispatch(alertActions.error(i18next.t('services.services.calendar.ShiftHourLimitExceed')))
                                    break
                                case DETAIL_ERROR_CODES.RequestShiftHoursCollapisng:
                                    dispatch(alertActions.error(i18next.t('services.services.calendar.collapseRequestShiftDays')))
                                    break
                                case DETAIL_ERROR_CODES.VehicleHasTrips:
                                    dispatch(alertActions.error(i18next.t('services.services.calendar.VehicleHasTrips')))
                                    break
                                case DETAIL_ERROR_CODES.ServiceHasTrips:
                                    dispatch(alertActions.error(i18next.t('services.services.calendar.ServiceHasTrips')))
                                    break
                                case DETAIL_ERROR_CODES.ExpeditionOutOfServiceWorkingHours:
                                    dispatch(alertActions.error(i18next.t('services.services.calendar.ExpeditionOutOfServiceWorkingHours')))
                                    break
                                case DETAIL_ERROR_CODES.WorkingBankHolidaysHoursCollapsing:
                                    dispatch(alertActions.error(i18next.t('services.services.calendar.WorkingBankHolidaysHoursCollapsing')))
                                    break
                                case DETAIL_ERROR_CODES.BreakShiftHourLimitExceed:
                                    dispatch(alertActions.error(i18next.t('services.services.calendar.BreakShiftHourLimitExceed')))
                                    break
                                case DETAIL_ERROR_CODES.BreakShiftHoursCollapisng:
                                    dispatch(alertActions.error(i18next.t('services.services.calendar.BreakShiftHoursCollapisng')))
                                    break
                                default:
                                    dispatch(alertActions.error(i18next.t('services.services.calendar.error')))
                                    break
                            }
                        } catch (e) {
                            dispatch(alertActions.error(error.toString()))
                        }
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.SET_CALENDAR_REQUEST }
    }

    function success(calendar) {
        return { type: servicesConstants.SET_CALENDAR_SUCCESS, calendar }
    }

    function failure(error) {
        return { type: servicesConstants.SET_CALENDAR_FAILURE, error }
    }
}

function remove(id) {
    return dispatch => {
        dispatch(request())
        serviceService._delete(id).then(
            service => {
                dispatch(success(id))
                dispatch(alertActions.success(i18next.t('services.services.deleteSuccess')))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(remove(id))
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.services.forbiddenDelete')))
                        break
                    default:
                        try {
                            let parsedError = JSON.parse(error)
                            let detailError = parsedError.detail
                            switch (detailError) {
                                case DETAIL_ERROR_CODES.ServiceHasTrips:
                                    dispatch(alertActions.error(i18next.t('services.services.ServiceHasTrips')))
                                    break
                                default:
                                    dispatch(alertActions.error(i18next.t('services.services.deleteError')))
                                    break
                            }
                        } catch (e) {
                            dispatch(alertActions.error(error.toString()))
                        }
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.DELETE_REQUEST }
    }

    function success(id) {
        return { type: servicesConstants.DELETE_SUCCESS, id }
    }

    function failure(error) {
        return { type: servicesConstants.DELETE_FAILURE, error }
    }
}

function disable(id) {
    return dispatch => {
        dispatch(request())
        serviceService.disable(id).then(
            service => {
                dispatch(success(id))
                dispatch(alertActions.success(i18next.t('services.services.disableSuccess')))
                dispatch(getAll())
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(disable(id))
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.services.forbiddenUpdate')))
                        break
                    default:
                        try {
                            let parsedError = JSON.parse(error)
                            let detailError = parsedError.detail
                            switch (detailError) {
                                default:
                                    dispatch(alertActions.error(i18next.t('services.services.disableError')))
                                    break
                            }
                        } catch (e) {
                            dispatch(alertActions.error(error.toString()))
                        }
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.DISABLE_REQUEST }
    }

    function success(id) {
        return { type: servicesConstants.DISABLE_SUCCESS, id }
    }

    function failure(error) {
        return { type: servicesConstants.DISABLE_FAILURE, error }
    }
}

function enable(id) {
    return dispatch => {
        dispatch(request())
        serviceService.enable(id).then(
            service => {
                dispatch(success(id))
                dispatch(alertActions.success(i18next.t('services.services.enableSuccess')))
                dispatch(getAll())
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(enable(id))
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.services.forbiddenUpdate')))
                        break
                    default:
                        try {
                            let parsedError = JSON.parse(error)
                            let detailError = parsedError.detail
                            switch (detailError) {
                                default:
                                    dispatch(alertActions.error(i18next.t('services.services.enableError')))
                                    break
                            }
                        } catch (e) {
                            dispatch(alertActions.error(error.toString()))
                        }
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.ENABLE_REQUEST }
    }

    function success(id) {
        return { type: servicesConstants.ENABLE_SUCCESS, id }
    }

    function failure(error) {
        return { type: servicesConstants.ENABLE_FAILURE, error }
    }
}

function sendPushToServiceUsers(body) {
    return dispatch => {
        serviceService.sendPushToServiceUsers(body).then(
            quantityNotificated => {
                if (quantityNotificated > 0)
                    dispatch(alertActions.success(i18next.t('services.services.sendPushToServiceUsersSuccess').replace('$', quantityNotificated.toString()) + body.serviceName))
                else
                    dispatch(alertActions.warning(i18next.t('services.services.sendPushToServiceUsersWarning') + body.serviceName))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(sendPushToServiceUsers(body))
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.services.forbiddenUpdate')))
                        break
                    default:
                        try {
                            let parsedError = JSON.parse(error)
                            let detailError = parsedError.detail
                            switch (detailError) {
                                default:
                                    dispatch(alertActions.error(i18next.t('services.services.sendPushToServiceUsersError')))
                                    break
                            }
                        } catch (e) {
                            dispatch(alertActions.error(error.toString()))
                        }
                }
            },
        )
    }
}

function getRoutes(filters = null) {
    return dispatch => {
        dispatch(request())
        serviceService.getRoutes().then(
            routes => {
                let all = routes.slice() //copy only by value
                if (filters != null) {
                    routes = filterPlainArray(routes, filters)
                }
                dispatch(success(routes, all))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (userService.existRefreshToken()) {
                            dispatch(userActions.refreshToken())
                            dispatch(getRoutes(filters))
                        }
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.services.forbiddenGetAll')))
                        break
                    default:
                        break
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.GET_ROUTES_REQUEST }
    }

    function success(routes, all) {
        return { type: servicesConstants.GET_ROUTES_SUCCESS, routes, all }
    }

    function failure(error) {
        return { type: servicesConstants.GET_ROUTES_FAILURE, error }
    }
}

function getRoutesFromVehicle(id, redirect) {
    return dispatch => {
        dispatch(request())
        serviceService.getRoutesFromVehicle(id).then(
            routes => {
                dispatch(success(routes))
                //dispatch(alertActions.success(i18next.t('services.services.deleteSuccess')))
                if (redirect) {
                    history.push('/vehicles/routes/' + id)
                }
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken())
                    dispatch(getRoutesFromVehicle(id))
                } else {
                    //dispatch(alertActions.error(i18next.t('services.services.deleteError')))
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.GET_ROUTES_FROM_VEHICLE_REQUEST }
    }

    function success(routes) {
        return {
            type: servicesConstants.GET_ROUTES_FROM_VEHICLE_SUCCESS,
            routes,
        }
    }

    function failure(error) {
        return {
            type: servicesConstants.GET_ROUTES_FROM_VEHICLE_FAILURE,
            error,
        }
    }
}

function getSpecificRoute(id, redirect) {
    return dispatch => {
        dispatch(request())
        serviceService.getSpecificRoute(id).then(
            route => {
                dispatch(success(route))
                //dispatch(alertActions.success(i18next.t('services.services.deleteSuccess')))
                if (redirect) {
                    history.push('/journeys/' + id)
                }
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken())
                    dispatch(getSpecificRoute(id))
                } else {
                    //dispatch(alertActions.error(i18next.t('services.services.deleteError')))
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.GET_SPECIFIC_ROUTE_REQUEST }
    }

    function success(route) {
        return { type: servicesConstants.GET_SPECIFIC_ROUTE_SUCCESS, route }
    }

    function failure(error) {
        return { type: servicesConstants.GET_SPECIFIC_ROUTE_FAILURE, error }
    }
}

function setWorkDays(days) {
    return dispatch => {
        dispatch(request(days))
    }

    function request(days) {
        return { type: servicesConstants.SET_WORKS_DAY, days }
    }
}

function setExcludeDays(range) {
    return dispatch => {
        range[0].startDate.setHours(0, 0, 0, 0)
        range[0].endDate.setHours(0, 0, 0, 0)
        let rangeObject = {
            startPeriod: moment(range[0].startDate).format("YYYY-MM-DDTHH:mm:ss"),
            finishPeriod: moment(range[0].endDate).format("YYYY-MM-DDTHH:mm:ss"),
        }
        dispatch(request(rangeObject))
    }

    function request(element) {
        return { type: servicesConstants.SET_EXCLUDE_DAYS, element }
    }
}

function setExcludedWorkingDays(workingRange) {
    return dispatch => {
        workingRange[0].startDate.setHours(0, 0, 0, 0)
        workingRange[0].endDate.setHours(0, 0, 0, 0)
        let workingRangeObject = {
            startPeriod: moment(workingRange[0].startDate).format("YYYY-MM-DDTHH:mm:ss"),
            finishPeriod: moment(workingRange[0].endDate).format("YYYY-MM-DDTHH:mm:ss"),
            shiftHours: [{ earliestStart: '', latestArrival: '' }],
        }
        dispatch(request(workingRangeObject))
    }

    function request(element) {
        return { type: servicesConstants.SET_EXCLUDED_WORKING_DAYS, element }
    }
}

function setRangeCalendar(range) {
    return dispatch => {
        dispatch(request(range))
    }

    function request(range) {
        return { type: servicesConstants.SET_RANGE_CALENDAR, range }
    }
}

function setWorkingRangeCalendar(workingRange) {
    return dispatch => {
        dispatch(request(workingRange))
    }

    function request(workingRange) {
        return {
            type: servicesConstants.SET_WORKING_RANGE_CALENDAR,
            workingRange,
        }
    }
}

function removeExcludeDays(index) {
    return dispatch => {
        dispatch(request(index))
    }

    function request(index) {
        return { type: servicesConstants.REMOVE_EXCLUDE_DAYS, index }
    }
}

function removeExcludedWorkingDays(index) {
    return dispatch => {
        dispatch(request(index))
    }

    function request(index) {
        return { type: servicesConstants.REMOVE_EXCLUDED_WORKING_DAYS, index }
    }
}

function removeTimeRequestShiftDays(index) {
    return dispatch => {
        dispatch(request(index))
    }

    function request(index) {
        return { type: servicesConstants.REMOVE_TIME_REQUESTSHIFT_DAY, index }
    }
}

function removeTimeShiftDays(index) {
    return dispatch => {
        dispatch(request(index))
    }

    function request(index) {
        return { type: servicesConstants.REMOVE_TIME_SHIFT_DAY, index }
    }
}

function removeTimeShiftBreaks(index) {
    return dispatch => {
        dispatch(request(index))
    }

    function request(index) {
        return { type: servicesConstants.REMOVE_TIME_SHIFT_BREAK, index }
    }
}

function removeTimeShiftHours(index, indexShiftHour) {
    return dispatch => {
        dispatch(request(index, indexShiftHour))
    }

    function request(index, indexShiftHour) {
        return {
            type: servicesConstants.REMOVE_TIME_SHIFT_HOUR,
            index,
            indexShiftHour,
        }
    }
}

function addTimeRequestShiftDays() {
    return dispatch => {
        dispatch(request())
    }

    function request() {
        return { type: servicesConstants.ADD_TIME_REQUESTSHIFT_DAY }
    }
}

function addTimeShiftDays() {
    return dispatch => {
        dispatch(request())
    }

    function request() {
        return { type: servicesConstants.ADD_TIME_SHIFT_DAY }
    }
}

function addTimeShiftBreak() {
    return dispatch => {
        dispatch(request())
    }

    function request() {
        return { type: servicesConstants.ADD_TIME_SHIFT_BREAK }
    }
}

function addTimeShiftHours(index) {
    return dispatch => {
        dispatch(request(index))
    }

    function request() {
        return { type: servicesConstants.ADD_TIME_SHIFT_HOUR, index }
    }
}

function setShiftDays(shiftDay) {
    return dispatch => {
        dispatch(request(shiftDay))
    }

    function request(shiftDay) {
        return { type: servicesConstants.SET_SHIFT_DAY, shiftDay }
    }
}

function setShiftBreaks(shiftBreak) {
    return dispatch => {
        dispatch(request(shiftBreak))
    }

    function request(shiftBreak) {
        return { type: servicesConstants.SET_SHIFT_BREAK, shiftBreak }
    }
}

function setRequestShiftDays(requestShiftDay) {
    return dispatch => {
        dispatch(request(requestShiftDay))
    }

    function request(requestShiftDay) {
        return {
            type: servicesConstants.SET_REQUEST_SHIFT_DAY,
            requestShiftDay,
        }
    }
}

function setShiftHours(shiftHour) {
    return dispatch => {
        dispatch(request(shiftHour))
    }

    function request(shiftHour) {
        return { type: servicesConstants.SET_SHIFT_HOUR, shiftHour }
    }
}

function getJourneys(filters = null, startDate = null, endDate = null) {
    return dispatch => {
        dispatch(request())
        //serviceService.getJourneys().then(
        serviceService.getOldJourneys(startDate, endDate).then(
            journeys => {
                let all = journeys.slice() //copy only by value
                if (filters != null) {
                    journeys = filterPlainArray(journeys, filters)
                }
                dispatch(success(journeys.data ? journeys.data : journeys, all))
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken())
                    dispatch(getJourneys(filters, startDate, endDate))
                } else {
                    //dispatch(alertActions.error(i18next.t('services.services.deleteError')))
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.GET_JOURNEYS_REQUEST }
    }

    function success(journeys, all) {
        return { type: servicesConstants.GET_JOURNEYS_SUCCESS, journeys, all }
    }

    function failure(error) {
        return { type: servicesConstants.GET_JOURNEYS_FAILURE, error }
    }
}

function setJourneyFilterDate(typeDate, value) {
    return dispatch => {
        dispatch(request(typeDate, value))
    }

    function request(typeDate, value) {
        return {
            type: servicesConstants.SET_FILTER_JOURNEY_DATES,
            typeDate,
            value,
        }
    }
}

function getJourneyActivities(journeyId) {
    return dispatch => {
        dispatch(request())
        serviceService.getJourneysActivities(journeyId).then(
            activities => {
                dispatch(success(activities))
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken())
                    dispatch(getJourneyActivities(journeyId))
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.GET_JOURNEYS_OBSERVATIONS_REQUEST }
    }

    function success(activities) {
        return {
            type: servicesConstants.GET_JOURNEYS_OBSERVATIONS_SUCCESS,
            activities,
        }
    }

    function failure(error) {
        return {
            type: servicesConstants.GET_JOURNEYS_OBSERVATIONS_FAILURE,
            error,
        }
    }
}

function addActivityJourney(activity, journeyId) {
    return dispatch => {
        dispatch(request())
        serviceService.addActivityJourney(activity, journeyId).then(
            result => {
                dispatch(success(result))
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken())
                    dispatch(addActivityJourney(activity, journeyId))
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.ADD_JOURNEYS_OBSERVATIONS_REQUEST }
    }

    function success(activities) {
        return {
            type: servicesConstants.ADD_JOURNEYS_OBSERVATIONS_SUCCESS,
            activities,
        }
    }

    function failure(error) {
        return {
            type: servicesConstants.ADD_JOURNEYS_OBSERVATIONS_FAILURE,
            error,
        }
    }
}

function getRouteStops(routeId) {
    return dispatch => {
        dispatch(request())
        serviceService.getRouteStops(routeId).then(
            routeStops => {
                dispatch(success(routeStops))
                //dispatch(alertActions.success(i18next.t('services.services.deleteSuccess')))
                /* if (redirect) {
                                            history.push('/journeys/')
                                        } */
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken())
                    dispatch(getRouteStops(routeId))
                } else {
                    //dispatch(alertActions.error(i18next.t('services.services.deleteError')))
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.GET_ROUTE_STOPS_REQUEST }
    }

    function success(routeStops) {
        return { type: servicesConstants.GET_ROUTE_STOPS_SUCCESS, routeStops }
    }

    function failure(error) {
        return { type: servicesConstants.GET_ROUTE_STOPS_FAILURE, error }
    }
}

function getJourneyTrips(journeyId, redirect = false) {
    return dispatch => {
        dispatch(request)
        serviceService.getJourneyTrips(journeyId).then(
            journeyTrips => {
                dispatch(success(journeyTrips))
                if (redirect) {
                    history.push({
                        pathname: `/journeys/trips`,
                        state: { journeyId, journeyTrips },
                    })
                }
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken())
                    dispatch(getJourneyTrips(journeyId))
                } else {
                    //dispatch(alertActions.error(i18next.t('services.services.deleteError')))
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.GET_TRIPS_REQUEST }
    }

    function success(journeyTrips) {
        return { type: servicesConstants.GET_TRIPS_SUCCESS, journeyTrips }
    }

    function failure(error) {
        return { type: servicesConstants.GET_TRIPS_FAILURE, error }
    }
}

function getTripStatuses() {
    return dispatch => {
        dispatch(request())
        const lang = i18next.language
        serviceService.getTripStatuses().then(
            results => {
                const prof = makeEnum(results)
                dispatch(success(prof))
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken())
                    dispatch(getTripStatuses(lang))
                } else {
                    //dispatch(alertActions.error(i18next.t('services.trips.getAllError')))
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.GET_TRIPS_TYPES_REQUEST }
    }

    function success(results) {
        return { type: servicesConstants.GET_TRIPS_TYPES_SUCCESS, results }
    }

    function failure(error) {
        return { type: servicesConstants.GET_TRIPS_TYPES_FAILURE, error }
    }
}

function getOldJourneys(noFilter = null, startDate = null, finishDate = null) {
    return dispatch => {
        dispatch(request())
        serviceService.getOldJourneys(startDate, finishDate).then(
            oldJourneys => {
                let all = oldJourneys.slice() //copy only by value
                if (noFilter != null) {
                    oldJourneys = filterPlainArray(oldJourneys, noFilter)
                }
                dispatch(success(oldJourneys, all))
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken())
                    dispatch(getOldJourneys(noFilter, startDate, finishDate))
                } else {
                    //dispatch(alertActions.error(i18next.t('services.services.deleteError')))
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.GET_OLD_JOURNEYS_REQUEST }
    }

    function success(oldJourneys, all) {
        return {
            type: servicesConstants.GET_OLD_JOURNEYS_SUCCESS,
            oldJourneys,
            all,
        }
    }

    function failure(error) {
        return { type: servicesConstants.GET_OLD_JOURNEYS_FAILURE, error }
    }
}

function getWheelJourneys(filters = null, startDate = null, finishDate = null) {
    return dispatch => {
        dispatch(request())
        serviceService.getWheelJourneys(startDate, finishDate).then(
            journeys => {
                let all = journeys.slice() //copy only by value
                if (filters != null) {
                    journeys = filterPlainArray(journeys, filters)
                }
                dispatch(success(journeys, all))
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken())
                    dispatch(getWheelJourneys(filters, startDate, finishDate))
                } else {
                    //dispatch(alertActions.error(i18next.t('services.services.deleteError')))
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.GET_JOURNEYS_REQUEST }
    }

    function success(journeys, all) {
        return { type: servicesConstants.GET_JOURNEYS_SUCCESS, journeys, all }
    }

    function failure(error) {
        return { type: servicesConstants.GET_JOURNEYS_FAILURE, error }
    }
}

function setRouteId(id) {
    return {
        type: servicesConstants.SET_ROUTE_ID,
        routeId: id,
    }
}

function getRouteId() {
    return {
        type: servicesConstants.GET_ROUTE_ID,
    }
}

function makeEnum(enumObject) {
    let all = []
    for (let key in enumObject) {
        all.push({
            id: key,
            name: enumObject[key],
        })
    }
    return all
}

function getDriverActivities(driverId) {
    return dispatch => {
        dispatch(request())
        serviceService.getDriverActivities(driverId).then(
            activities => {
                dispatch(success(activities))
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken())
                    dispatch(getJourneyActivities(driverId))
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.GET_DRIVER_OBSERVATIONS_REQUEST }
    }

    function success(activities) {
        return {
            type: servicesConstants.GET_DRIVER_OBSERVATIONS_SUCCESS,
            activities,
        }
    }

    function failure(error) {
        return {
            type: servicesConstants.GET_DRIVER_OBSERVATIONS_FAILURE,
            error,
        }
    }
}

function addActivityDriver(activity, driverId) {
    return dispatch => {
        dispatch(request())
        serviceService.addActivityDriver(activity, driverId).then(
            result => {
                dispatch(success(result))
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken())
                    dispatch(addActivityJourney(activity, driverId))
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.ADD_DRIVER_OBSERVATIONS_REQUEST }
    }

    function success(activities) {
        return {
            type: servicesConstants.ADD_DRIVER_OBSERVATIONS_SUCCESS,
            activities,
        }
    }

    function failure(error) {
        return {
            type: servicesConstants.ADD_DRIVER_OBSERVATIONS_FAILURE,
            error,
        }
    }
}

//NEW FOR CUSTOMERS
function getCustomerActivities(customerId) {
    return dispatch => {
        dispatch(request())
        serviceService.getCustomerActivities(customerId).then(
            activities => {
                dispatch(success(activities))
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken())
                    dispatch(getCustomerActivities(customerId))
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.GET_CUSTOMER_OBSERVATIONS_REQUEST }
    }

    function success(activities) {
        return {
            type: servicesConstants.GET_CUSTOMER_OBSERVATIONS_SUCCESS,
            activities,
        }
    }

    function failure(error) {
        return {
            type: servicesConstants.GET_CUSTOMER_OBSERVATIONS_FAILURE,
            error,
        }
    }
}

// NEW FOR TRIPS
function getTripActivities(tripId) {
    return dispatch => {
        dispatch(request())
        serviceService.getTripActivities(tripId).then(
            activities => {
                dispatch(success(activities))
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken())
                    dispatch(getTripActivities(tripId))
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.GET_TRIP_OBSERVATIONS_REQUEST }
    }

    function success(activities) {
        return {
            type: servicesConstants.GET_TRIP_OBSERVATIONS_SUCCESS,
            activities,
        }
    }

    function failure(error) {
        return {
            type: servicesConstants.GET_TRIP_OBSERVATIONS_FAILURE,
            error,
        }
    }
}

function getTypeTipologies() {
    return dispatch => {
        dispatch(request())
        serviceService.getTypeTipologies().then(
            typologies => {
                const typ = makeEnum(typologies)
                dispatch(success(typ))
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken())
                    dispatch(getTypeTipologies())
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.GET_TYPE_TYPOLOGY_REQUEST }
    }

    function success(typologies) {
        return { type: servicesConstants.GET_TYPE_TYPOLOGY_SUCCESS, typologies }
    }

    function failure(error) {
        return { type: servicesConstants.GET_TYPE_TYPOLOGY_FAILURE, error }
    }
}

function getServiceLine(serviceId) {
    return dispatch => {
        dispatch(request())
        serviceService.getServiceLine(serviceId).then(
            line => {
                dispatch(success(line))
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken())
                    dispatch(getServiceLine(serviceId))
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.GET_SERVICE_LINE_REQUEST }
    }

    function success(line) {
        return { type: servicesConstants.GET_SERVICE_LINE_SUCCESS, line }
    }

    function failure(error) {
        return { type: servicesConstants.GET_SERVICE_LINE_FAILURE, error }
    }
}

function getServiceLineStopsAvailable(serviceId, direction) {
    return dispatch => {
        dispatch(request(direction))
        serviceService.getServiceLineAvailableStops(serviceId, direction).then(
            stops => {
                if (direction == 10) dispatch(successSingle(stops))
                else dispatch(successReturn(stops))
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken())
                    dispatch(getServiceLineStopsAvailable(serviceId, direction))
                }
                if (direction == 10) dispatch(failureSingle(error))
                else dispatch(failureReturn(error))
            },
        )
    }

    function request(direction) {
        return {
            type: direction == 10 ? servicesConstants.GET_SERVICE_LINE_STOPS_AVAILABLE_SINGLE_REQUEST : servicesConstants.GET_SERVICE_LINE_STOPS_AVAILABLE_RETURN_REQUEST,
        }
    }

    function successSingle(stops) {
        return {
            type: servicesConstants.GET_SERVICE_LINE_STOPS_AVAILABLE_SINGLE_SUCCESS,
            stops,
        }
    }

    function successReturn(stops) {
        return {
            type: servicesConstants.GET_SERVICE_LINE_STOPS_AVAILABLE_RETURN_SUCCESS,
            stops,
        }
    }

    function failureSingle(error) {
        return {
            type: servicesConstants.GET_SERVICE_LINE_STOPS_AVAILABLE_SINGLE_FAILURE,
            error,
        }
    }

    function failureReturn(error) {
        return {
            type: servicesConstants.GET_SERVICE_LINE_STOPS_AVAILABLE_RETURN_FAILURE,
            error,
        }
    }
}

function getAnticipationTypes() {
    return dispatch => {
        dispatch(request())
        serviceService.anticipationTypes().then(
            anticipations => {
                const typ = makeEnum(anticipations)
                dispatch(success(typ))
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken())
                    dispatch(getTypeTipologies())
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.GET_ANTICIPATION_TYPES_REQUEST }
    }

    function success(anticipations) {
        return {
            type: servicesConstants.GET_ANTICIPATION_TYPES_SUCCESS,
            anticipations,
        }
    }

    function failure(error) {
        return { type: servicesConstants.GET_ANTICIPATION_TYPES_FAILURE, error }
    }
}

function getPeriodicity() {
    return dispatch => {
        dispatch(request())
        serviceService.getTipologyPeriodicity().then(
            periodicities => {
                const period = makeEnum(periodicities)
                dispatch(success(period))
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken())
                    dispatch(getPeriodicity())
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.GET_TYPE_PERIODICITY_REQUEST }
    }

    function success(periodicities) {
        return {
            type: servicesConstants.GET_TYPE_PERIODICITY_SUCCESS,
            periodicities,
        }
    }

    function failure(error) {
        return { type: servicesConstants.GET_TYPE_PERIODICITY_FAILURE, error }
    }
}

function addActivityCustomer(activity, customerId) {
    return dispatch => {
        dispatch(request())
        serviceService.addActivityCustomer(activity, customerId).then(
            result => {
                dispatch(success(result))
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken())
                    dispatch(addActivityCustomer(activity, customerId))
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.ADD_CUSTOMER_OBSERVATIONS_REQUEST }
    }

    function success(activities) {
        return {
            type: servicesConstants.ADD_CUSTOMER_OBSERVATIONS_SUCCESS,
            activities,
        }
    }

    function failure(error) {
        return {
            type: servicesConstants.ADD_CUSTOMER_OBSERVATIONS_FAILURE,
            error,
        }
    }
}

function addActivityTrip(activity, tripId) {
    return dispatch => {
        dispatch(request())
        serviceService.addActivityTrip(activity, tripId).then(
            result => {
                dispatch(success(result))
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken())
                    dispatch(addActivityTrip(activity, tripId))
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.ADD_TRIP_OBSERVATIONS_REQUEST }
    }

    function success(activities) {
        return {
            type: servicesConstants.ADD_TRIP_OBSERVATIONS_SUCCESS,
            activities,
        }
    }

    function failure(error) {
        return {
            type: servicesConstants.ADD_TRIP_OBSERVATIONS_FAILURE,
            error,
        }
    }
}

function setServiceFilter(prop, values, filters) {
    return dispatch => {
        dispatch(request(prop, values, filters))
    }
    function request(prop, values, filters) {
        return {
            type: servicesConstants.SET_FILTER_SERVICE,
            prop,
            values,
            filters,
        }
    }
}

function setRouteFilter(prop, values, filters) {
    return dispatch => {
        dispatch(request(prop, values, filters))
    }

    function request(prop, values, filters) {
        return {
            type: servicesConstants.SET_FILTER_ROUTE,
            prop,
            values,
            filters,
        }
    }
}

function setJourneyFilter(prop, values, filters) {
    return dispatch => {
        dispatch(request(prop, values, filters))
    }

    function request(prop, values, filters) {
        return {
            type: servicesConstants.SET_FILTER_JOURNEY,
            prop,
            values,
            filters,
        }
    }
}

function getKPITypes(lang) {
    return dispatch => {
        dispatch(request())
        serviceService.getKPITypes(lang).then(
            result => {
                dispatch(success(result))
            },
            error => {
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.GET_KPI_TYPES_REQUEST }
    }

    function success(results) {
        return { type: servicesConstants.GET_KPI_TYPES_SUCCESS, results }
    }

    function failure(error) {
        return { type: servicesConstants.GET_KPI_TYPES_FAILURE, error }
    }
}

function getKPIsByType(id, lang) {
    return dispatch => {
        dispatch(request())
        serviceService.getKPIsByType(id, lang).then(
            results => {
                dispatch(success(results))
            },
            error => {
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.GET_KPIS_BY_TYPE_REQUEST }
    }

    function success(results) {
        return { type: servicesConstants.GET_KPIS_BY_TYPE_SUCCESS, results }
    }

    function failure(error) {
        return { type: servicesConstants.GET_KPIS_BY_TYPE_FAILURE, error }
    }
}

function getKPIFiltersByGroup(key, lng) {
    return dispatch => {
        dispatch(request())
        serviceService.getKPIGroups(key, lng).then(
            results => {
                dispatch(success(results))
            },
            error => {
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.GET_KPIS_FILTER_GROUP_REQUEST }
    }

    function success(results) {
        return {
            type: servicesConstants.GET_KPIS_FILTER_GROUP_SUCCESS,
            results,
        }
    }

    function failure(error) {
        return { type: servicesConstants.GET_KPIS_FILTER_GROUP_FAILURE, error }
    }
}

function getKPITimeUnits(lng) {
    return dispatch => {
        dispatch(request())
        serviceService.getKPITimeUnits(lng).then(
            results => {
                dispatch(success(results))
            },
            error => {
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.GET_KPI_TIME_UNITS_REQUEST }
    }

    function success(results) {
        return { type: servicesConstants.GET_KPI_TIME_UNITS_SUCCESS, results }
    }

    function failure(error) {
        return { type: servicesConstants.GET_KPI_TIME_UNITS_FAILURE, error }
    }
}

function getKPITripOrigins(lng) {
    return dispatch => {
        dispatch(request())
        serviceService.getKPITripOrigins(lng).then(
            results => {
                dispatch(success(results))
            },
            error => {
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: servicesConstants.GET_KPI_CHANNEL_REQUEST }
    }

    function success(results) {
        return { type: servicesConstants.GET_KPI_CHANNEL_SUCCESS, results }
    }

    function failure(error) {
        return { type: servicesConstants.GET_KPI_CHANNEL_FAILURE, error }
    }
}

function saveTableFilters(tableFilters) {
    return dispatch => {
        dispatch(save(tableFilters))
    }

    function save(tableFilters) {
        return { type: servicesConstants.SAVE_TABLE_FILTERS, tableFilters }
    }
}

function saveJourneysTableFilters(journeysTableFilters) {
    return dispatch => {
        dispatch(save(journeysTableFilters))
    }

    function save(journeysTableFilters) {
        return { type: servicesConstants.SAVE_JOURNEYS_TABLE_FILTERS, journeysTableFilters }
    }
}

function cleanJourneyFilters() {
    return dispatch => {
        dispatch(clean())
    }

    function clean() {
        return { type: servicesConstants.CLEAN_FILTER_JOURNEY }
    }
}