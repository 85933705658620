import { FormControl, Grid, Hidden, TextField, Checkbox, FormControlLabel } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const styles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

class FilterMarkers extends React.Component {

    constructor(props) {
        super()
        this.state = {
            otp: [],
            service: [],
            plate: [],
            zone: []
        }
    }

    onChangeOtp = (event, newValue) => {
        this.setState({
            otp: newValue
        })
        this.props.setFilter('otp', newValue.map(item => item.id))
    }

    onChangeService = (event, newValue) => {
        this.setState({
            service: newValue
        })
        this.props.setFilter('service', newValue.map(item => item.id))
    }

    onChangePlate = (event, newValue) => {
        this.setState({
            plate: newValue
        })
        this.props.setFilter('plate', newValue)
    }

    onChangeZone = (event, newValue) => {
        this.setState({
            zone: newValue
        })
        this.props.setFilter('zone', newValue.map(item => item.id))
    }

    render() {
        const { vehicleReducer, zoneReducer, otpReducer, serviceReducer, t } = this.props
        const plates = vehicleReducer.results.map(v => v.plateNumber)
        const zones = zoneReducer.results
        return (
            <React.Fragment>
                <Grid container className={styles.root} spacing={3} style={{
                    marginBottom: '16px', marginTop: '16px'
                }}>
                    <Grid item md={1} implementation="css" smDown component={Hidden} />
                    <Grid item md={2} xs={6} sm={3}>
                        <FormControl fullWidth>
                            <Autocomplete
                                multiple
                                id="otpId"
                                value={this.state.otp}
                                onChange={this.onChangeOtp}
                                options={otpReducer.results}
                                getOptionSelected={(option, value) => option.id === value.id}
                                getOptionLabel={(option) => option.name ? option.name : ''}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant={'outlined'}
                                        label={t('maps.filter.otp')}
                                        name="otp"
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={2} xs={6} sm={3}>
                        <FormControl fullWidth>
                            <Autocomplete
                                multiple
                                id="serviceId"
                                value={this.state.service}
                                onChange={this.onChangeService}
                                options={serviceReducer.results}
                                getOptionSelected={(option, value) => option.id === value.id}
                                getOptionLabel={(option) => option.name ? option.name : ''}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant={'outlined'}
                                        label={t('maps.filter.service')}
                                        name="serviceId"
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={2} xs={6} sm={3}>
                        <FormControl fullWidth>
                            <Autocomplete
                                multiple
                                id="plate"
                                value={this.state.plate}
                                onChange={this.onChangePlate}
                                options={plates}
                                getOptionSelected={(option, value) => option === value}
                                getOptionLabel={(option) => option ? option : ''}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant={'outlined'}
                                        label={t('maps.filter.plate')}
                                        name="plate"
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={2} xs={6} sm={3}>
                        <FormControl fullWidth>
                            <Autocomplete
                                multiple
                                id="zone"
                                value={this.state.zone}
                                onChange={this.onChangeZone}
                                options={zones}
                                getOptionSelected={(option, value) => option.id === value.id}
                                getOptionLabel={(option) => option ? option.name : ''}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant={'outlined'}
                                        label={t('maps.filter.zone')}
                                        name="zone"
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

export default FilterMarkers;