import React, { Suspense } from 'react'
import { Router, Switch, Redirect, Route } from 'react-router-dom'
import Login from './components/login'
import { history } from './helpers/history'
import PrivateRoute from './PrivateRoute'
import { NoAuthPage } from './components/no-auth-page'

import Operators from './components/operators'
import OperatorCreate from './components/operators/create'
import OperatorDetail from './components/operators/details'
import OperatorEdit from './components/operators/edit'

import Drivers from './components/drivers'
import DriverCreate from './components/drivers/create'
import DriverDetail from './components/drivers/details'
import DriverEdit from './components/drivers/edit'

import Vehicles from './components/vehicles'
import VehiclesCreate from './components/vehicles/create'
import VehicleDetail from './components/vehicles/details'
import VehicleEdit from './components/vehicles/edit'
import VehicleCalendar from './components/vehicles/calendar'

import Otp from './components/otp'
import OtpCreate from './components/otp/create'
import OtpDetail from './components/otp/details'
import OtpEdit from './components/otp/edit'

import Zones from './components/zones'
import ZoneStops from './components/zones/stops'

import Services from './components/services'
import ServicesCreate from './components/services/create'
import ServicesDetail from './components/services/details'
import ServicesEdit from './components/services/edit'
import ServiceStops from './components/services/stops'
import ServiceCalendar from './components/services/calendar'

import MapsService from './components/maps'
import Trips from './components/trips'

import Customers from './components/customers'
import CustomerCreate from './components/customers/create'
import CustomerDetail from './components/customers/details'
import CustomerEdit from './components/customers/edit'
import CustomerTrips from './components/customers/trips'

import RequestTrip from './components/requestTrips/request'
import i18next from 'i18next'
import ServiceLines from './components/services/lines'
import ServiceExpeditions from './components/services/expeditions'
import LinesByService from './components/services/lines/withSchedule'
import Dashboard from './components/dashboard'
import Spinner from './components/shared/spinner'
import { MODULES, OPERATIONS_ALLOWED } from './components/roles/constants'
import CommandBox from './components/commandBox/kpis/summary'
import TripsKPI from './components/commandBox/kpis/trips'
import JourneysKPI from './components/commandBox/kpis/journeys'
import ReservationsKPI from './components/commandBox/kpis/reservations'
import UsersKPI from './components/commandBox/kpis/users'
import DriversKPI from './components/commandBox/kpis/drivers'
import VehiclesKPI from './components/commandBox/kpis/vehicles'
import economyDataKPI from './components/commandBox/kpis/economyData'
import originDestinationKPI from './components/commandBox/kpis/originDestination'
import KpiLayout from './components/commandBox/layout'

const Roles = React.lazy(() => import('./components/roles'))
const RolesCreate = React.lazy(() => import('./components/roles/create'))
const RolesDetails = React.lazy(() => import('./components/roles/details'))
const RolesEdit = React.lazy(() => import('./components/roles/edit'))

const Profile = React.lazy(() => import('./components/profile'))
const ResetPassword = React.lazy(() => import('./components/resetPassword'))
const UpdatePassword = React.lazy(() => import('./components/updatePassword'))

const Tenants = React.lazy(() => import('./components/tenants'))
const TenantCreate = React.lazy(() => import('./components/tenants/create'))
const TenantDetail = React.lazy(() => import('./components/tenants/details'))
const TenantEdit = React.lazy(() => import('./components/tenants/edit'))

const CommentsJourneys = React.lazy(() => import('./components/journeys/comments'))
const CommentsDrivers = React.lazy(() => import('./components/drivers/comments'))
const CommentsCustomers = React.lazy(() => import('./components/customers/comments'))
const CommentsTrips = React.lazy(() => import('./components/customers/trips/comments'))

const Reports = React.lazy(() => import('./components/reports'))

const Journeys = React.lazy(() => import('./components/journeys'))
const JourneyDetails = React.lazy(() => import('./components/journeys/details'))
const JourneyTrips = React.lazy(() => import('./components/journeyTrips'))

const Routes = React.lazy(() => import('./components/routes'))
const RouteDetail = React.lazy(() => import('./components/routes/details'))

const Incidents = React.lazy(() => import('./components/incidents'))
const IncidentDetail = React.lazy(() => import('./components/incidents/details'))
const incidentEdit = React.lazy(() => import('./components/incidents/edit'))
const CreateIncidents = React.lazy(() => import('./components/incidents/create'))

const ServiceCustomerTypology = React.lazy(() => import('./components/services/typology/price'))
const ServiceCostTypology = React.lazy(() => import('./components/services/typology/cost'))
const TypologyServiceWithSchedule = React.lazy(() => import('./components/services/typology/withSchedule/index'))

const Chat = React.lazy(() => import('./components/chat'))

const Notifications = React.lazy(() => import('./components/notifications/system'))
const NotificationsCustomers = React.lazy(() => import('./components/notifications/customer'))


class Root extends React.Component {
    componentDidMount() {
        document.title = i18next.t('dashboard.title')
    }

    render() {
        return (
            <Router history={history} >
                <Suspense fallback={<Dashboard component={<Spinner loading={true} />} />}>
                    <Switch>
                        <PrivateRoute
                            path={`/home`}
                            component={MapsService}
                            modules={MODULES.Home}
                            operationAllowed={OPERATIONS_ALLOWED.read}
                            nameTenant={this.props.nameTenant}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/profile`}
                            modules={MODULES.My_Profile}
                            operationAllowed={OPERATIONS_ALLOWED.read}
                            component={Profile}
                        ></PrivateRoute>
                        <Route path={`/login`} component={Login}></Route>
                        <Route
                            path={`/resetPassword`}
                            component={ResetPassword}
                        ></Route>
                        <Route
                            path={`/updatePassword`}
                            component={UpdatePassword}
                        ></Route>
                        <PrivateRoute
                            path={`/tenants/edit/:id`}
                            component={TenantEdit}
                            modules={MODULES.Tenants}
                            operationAllowed={OPERATIONS_ALLOWED.manage}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/tenants/create`}
                            component={TenantCreate}
                            modules={MODULES.Tenants}
                            operationAllowed={OPERATIONS_ALLOWED.manage}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/tenants/:id`}
                            component={TenantDetail}
                            modules={MODULES.Tenants}
                            operationAllowed={OPERATIONS_ALLOWED.read}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/tenants`}
                            component={Tenants}
                            modules={MODULES.Tenants}
                            operationAllowed={OPERATIONS_ALLOWED.read}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/roles/edit/:id`}
                            component={RolesEdit}
                            modules={MODULES.BO_Profiles}
                            operationAllowed={OPERATIONS_ALLOWED.manage}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/roles/create`}
                            component={RolesCreate}
                            modules={MODULES.BO_Profiles}
                            operationAllowed={OPERATIONS_ALLOWED.manage}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/roles/:id`}
                            component={RolesDetails}
                            modules={MODULES.BO_Profiles}
                            operationAllowed={OPERATIONS_ALLOWED.read}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/roles`}
                            component={Roles}
                            modules={MODULES.BO_Profiles}
                            operationAllowed={OPERATIONS_ALLOWED.read}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/operators/edit/:id`}
                            component={OperatorEdit}
                            modules={MODULES.BO_Profiles}
                            operationAllowed={OPERATIONS_ALLOWED.manage}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/operators/create`}
                            component={OperatorCreate}
                            modules={MODULES.BO_Profiles}
                            operationAllowed={OPERATIONS_ALLOWED.manage}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/operators/:id`}
                            component={OperatorDetail}
                            modules={MODULES.BO_Profiles}
                            operationAllowed={OPERATIONS_ALLOWED.read}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/operators`}
                            component={Operators}
                            modules={MODULES.BO_Profiles}
                            operationAllowed={OPERATIONS_ALLOWED.read}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/vehicles/configCalendar/:id`}
                            component={VehicleCalendar}
                            modules={MODULES.Vehicles}
                            operationAllowed={OPERATIONS_ALLOWED.manage}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/vehicles/create`}
                            component={VehiclesCreate}
                            modules={MODULES.Vehicles}
                            operationAllowed={OPERATIONS_ALLOWED.manage}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/vehicles/edit/:id`}
                            component={VehicleEdit}
                            modules={MODULES.Vehicles}
                            operationAllowed={OPERATIONS_ALLOWED.manage}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/vehicles/:id`}
                            component={VehicleDetail}
                            modules={MODULES.Vehicles}
                            operationAllowed={OPERATIONS_ALLOWED.read}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/vehicles`}
                            component={Vehicles}
                            modules={MODULES.Vehicles}
                            operationAllowed={OPERATIONS_ALLOWED.read}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/drivers/observations/:id`}
                            component={CommentsDrivers}
                            modules={MODULES.Drivers}
                            operationAllowed={OPERATIONS_ALLOWED.read}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/drivers/edit/:id`}
                            component={DriverEdit}
                            modules={MODULES.Drivers}
                            operationAllowed={OPERATIONS_ALLOWED.manage}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/drivers/create`}
                            component={DriverCreate}
                            modules={MODULES.Drivers}
                            operationAllowed={OPERATIONS_ALLOWED.manage}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/drivers/:id`}
                            component={DriverDetail}
                            modules={MODULES.Drivers}
                            operationAllowed={OPERATIONS_ALLOWED.read}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/drivers`}
                            component={Drivers}
                            modules={MODULES.Drivers}
                            operationAllowed={OPERATIONS_ALLOWED.read}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/otps/edit/:id`}
                            component={OtpEdit}
                            modules={MODULES.OTP}
                            operationAllowed={OPERATIONS_ALLOWED.manage}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/otps/create`}
                            component={OtpCreate}
                            modules={MODULES.OTP}
                            operationAllowed={OPERATIONS_ALLOWED.manage}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/otps/:id`}
                            component={OtpDetail}
                            modules={MODULES.OTP}
                            operationAllowed={OPERATIONS_ALLOWED.read}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/otps`}
                            component={Otp}
                            modules={MODULES.OTP}
                            operationAllowed={OPERATIONS_ALLOWED.read}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/zones/stops`}
                            component={ZoneStops}
                            modules={MODULES.Zones}
                            operationAllowed={OPERATIONS_ALLOWED.manage}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/zones`}
                            component={Zones}
                            modules={MODULES.Zones}
                            operationAllowed={OPERATIONS_ALLOWED.manage}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/services/addStop/:id`}
                            component={ServiceStops}
                            modules={MODULES.Services}
                            operationAllowed={OPERATIONS_ALLOWED.manage}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/services/getLines/:id`}
                            component={LinesByService}
                            modules={MODULES.Services}
                            operationAllowed={OPERATIONS_ALLOWED.manage}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/services/setExpeditions/:serviceId/:serviceType/:lineId?`}
                            component={ServiceExpeditions}
                            modules={MODULES.Services}
                            operationAllowed={OPERATIONS_ALLOWED.manage}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/services/setLines/:serviceId/:serviceType/:lineId?`}
                            component={ServiceLines}
                            modules={MODULES.Services}
                            operationAllowed={OPERATIONS_ALLOWED.manage}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/services/configCalendar/:id/:serviceType`}
                            component={ServiceCalendar}
                            modules={MODULES.Services}
                            operationAllowed={OPERATIONS_ALLOWED.manage}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/services/setCustomerTypology/:id/:type`}
                            component={ServiceCustomerTypology}
                            modules={MODULES.Services}
                            operationAllowed={OPERATIONS_ALLOWED.manage}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/services/setCustomerTypologyLine/:id`}
                            component={TypologyServiceWithSchedule}
                            modules={MODULES.Services}
                            operationAllowed={OPERATIONS_ALLOWED.manage}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/services/setCostTypology/:id`}
                            component={ServiceCostTypology}
                            modules={MODULES.Services}
                            operationAllowed={OPERATIONS_ALLOWED.manage}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/services/map`}
                            component={MapsService}
                            modules={MODULES.Services}
                            operationAllowed={OPERATIONS_ALLOWED.read}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/services/edit/:id`}
                            component={ServicesEdit}
                            modules={MODULES.Services}
                            operationAllowed={OPERATIONS_ALLOWED.manage}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/services/create`}
                            component={ServicesCreate}
                            modules={MODULES.Services}
                            operationAllowed={OPERATIONS_ALLOWED.manage}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/services/:id`}
                            component={ServicesDetail}
                            modules={MODULES.Services}
                            operationAllowed={OPERATIONS_ALLOWED.read}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/services`}
                            component={Services}
                            modules={MODULES.Services}
                            operationAllowed={OPERATIONS_ALLOWED.read}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/journeys/observations/:id`}
                            component={CommentsJourneys}
                            modules={MODULES.Journeys}
                            operationAllowed={OPERATIONS_ALLOWED.read}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/journeys/trips`}
                            component={JourneyTrips}
                            modules={MODULES.Journeys}
                            operationAllowed={OPERATIONS_ALLOWED.read}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/journeys/:id`}
                            component={JourneyDetails}
                            modules={MODULES.Journeys}
                            operationAllowed={OPERATIONS_ALLOWED.read}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/journeysWheel`}
                            component={Journeys}
                            modules={MODULES.Journeys}
                            operationAllowed={OPERATIONS_ALLOWED.read}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/journeysFlex`}
                            component={Journeys}
                            modules={MODULES.Journeys}
                            operationAllowed={OPERATIONS_ALLOWED.read}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/routes/:id`}
                            component={RouteDetail}
                            modules={MODULES.Routes}
                            operationAllowed={OPERATIONS_ALLOWED.read}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/routes`}
                            component={Routes}
                            modules={MODULES.Routes}
                            operationAllowed={OPERATIONS_ALLOWED.read}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/incidents/create`}
                            component={CreateIncidents}
                            modules={MODULES.Incidents}
                            operationAllowed={OPERATIONS_ALLOWED.manage}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/incidents/edit/:id`}
                            component={incidentEdit}
                            modules={MODULES.Incidents}
                            operationAllowed={OPERATIONS_ALLOWED.manage}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/incidents/:id`}
                            component={IncidentDetail}
                            modules={MODULES.Incidents}
                            operationAllowed={OPERATIONS_ALLOWED.read}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/incidents`}
                            component={Incidents}
                            modules={MODULES.Incidents}
                            operationAllowed={OPERATIONS_ALLOWED.read}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/notifications`}
                            component={Notifications}
                            modules={MODULES.Notifications}
                            operationAllowed={OPERATIONS_ALLOWED.read}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/notificationsPush`}
                            component={NotificationsCustomers}
                            modules={MODULES.Notifications}
                            operationAllowed={OPERATIONS_ALLOWED.manage}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/chat/:id`}
                            component={Chat}
                            modules={MODULES.Chat}
                            operationAllowed={OPERATIONS_ALLOWED.read}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/chat`}
                            component={Chat}
                            modules={MODULES.Chat}
                            operationAllowed={OPERATIONS_ALLOWED.read}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/customers/trips/observations/:id`}
                            component={CommentsTrips}
                            modules={MODULES.Users}
                            operationAllowed={OPERATIONS_ALLOWED.read}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/customers/create`}
                            component={CustomerCreate}
                            modules={MODULES.Users}
                            operationAllowed={OPERATIONS_ALLOWED.manage}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/customers/trips/:id`}
                            component={CustomerTrips}
                            modules={MODULES.Users}
                            operationAllowed={OPERATIONS_ALLOWED.read}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/customers/edit/:id`}
                            component={CustomerEdit}
                            modules={MODULES.Users}
                            operationAllowed={OPERATIONS_ALLOWED.manage}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/customers/observations/:id`}
                            component={CommentsCustomers}
                            modules={MODULES.Users}
                            operationAllowed={OPERATIONS_ALLOWED.read}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/customers/:id`}
                            component={CustomerDetail}
                            modules={MODULES.Users}
                            operationAllowed={OPERATIONS_ALLOWED.read}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/customers`}
                            component={Customers}
                            modules={MODULES.Users}
                            operationAllowed={OPERATIONS_ALLOWED.read}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/requestTrip`}
                            component={RequestTrip}
                            modules={MODULES.Trips}
                            operationAllowed={OPERATIONS_ALLOWED.manage}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/resumeTrip`}
                            component={RequestTrip}
                            modules={[MODULES.Trips, MODULES.Journeys]}
                            operationAllowed={OPERATIONS_ALLOWED.read}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/tripsPending`}
                            component={Trips}
                            modules={MODULES.Trips}
                            operationAllowed={OPERATIONS_ALLOWED.read}
                        ></PrivateRoute>
                        <PrivateRoute
                            path={`/tripsAll`}
                            component={Trips}
                            modules={MODULES.Trips}
                            operationAllowed={OPERATIONS_ALLOWED.read}
                        ></PrivateRoute>

                        <PrivateRoute
                            path={`/reports`}
                            component={Reports}
                            modules={MODULES.Drivers}
                            operationAllowed={OPERATIONS_ALLOWED.read}
                        ></PrivateRoute>
                        <Route path="/no-Auth" component={NoAuthPage} />
                        <Route path="/" exact render={() => <Redirect to={`/home`} />} />
                        <KpiLayout>
                            <PrivateRoute
                                path={`/commandBox/trips`}
                                component={TripsKPI}
                                modules={MODULES.KPIs_dashboard}
                                operationAllowed={OPERATIONS_ALLOWED.read}
                            ></PrivateRoute>
                            <PrivateRoute
                                path={`/commandBox/journeys`}
                                component={JourneysKPI}
                                modules={MODULES.KPIs_dashboard}
                                operationAllowed={OPERATIONS_ALLOWED.read}
                            ></PrivateRoute>
                            <PrivateRoute
                                path={`/commandBox/reservations`}
                                component={ReservationsKPI}
                                modules={MODULES.KPIs_dashboard}
                                operationAllowed={OPERATIONS_ALLOWED.read}
                            ></PrivateRoute>
                            <PrivateRoute
                                path={`/commandBox/users`}
                                component={UsersKPI}
                                modules={MODULES.KPIs_dashboard}
                                operationAllowed={OPERATIONS_ALLOWED.read}
                            ></PrivateRoute>
                            <PrivateRoute
                                path={`/commandBox/drivers`}
                                component={DriversKPI}
                                modules={MODULES.KPIs_dashboard}
                                operationAllowed={OPERATIONS_ALLOWED.read}
                            ></PrivateRoute>
                            <PrivateRoute
                                path={`/commandBox/vehicles`}
                                component={VehiclesKPI}
                                modules={MODULES.KPIs_dashboard}
                                operationAllowed={OPERATIONS_ALLOWED.read}
                            ></PrivateRoute>
                            <PrivateRoute
                                path={`/commandBox/economyData`}
                                component={economyDataKPI}
                                modules={MODULES.KPIs_dashboard}
                                operationAllowed={OPERATIONS_ALLOWED.read}
                            ></PrivateRoute>
                            <PrivateRoute
                                path={`/commandBox/originDestination`}
                                component={originDestinationKPI}
                                modules={MODULES.KPIs_dashboard}
                                operationAllowed={OPERATIONS_ALLOWED.read}
                            ></PrivateRoute>
                            <PrivateRoute
                                path={`/commandBox`}
                                component={CommandBox}
                                modules={MODULES.KPIs_dashboard}
                                operationAllowed={OPERATIONS_ALLOWED.read}
                            ></PrivateRoute>
                        </KpiLayout>
                    </Switch>
                </Suspense>
            </Router >
        )
    }
}




export default Root
